<template>
    <section>
        <div class='parent'>
            <h1 class='round'>עריכת שאלות</h1>
        </div>
        <div id='editor'>
            <ul class='categorizedList list'>
                <li
                    v-for='question in questions'
                    :key='question.id'
                    @click='select(question)'
                    :class='{ edited: question.edited || isGenderized(question), selected: question.id === selectedQuestion?.id }'
                >
                    {{ $parse(question.text.old) }}
                </li>
            </ul>
            <div class='editing'>
                <div v-if='selectedQuestion'>
                    <label>
                        טקסט
                        <div class='input'>
                            <input v-model='text.new' @input='touch(text)'/>
                            <img src='/images-new/gender.svg' @click.stop='genderize(text)'/>
                        </div>
                        <!-- <div class='input'>
                            <input class='english' v-model='selected.text.en.text' @input='touch(selected.text.en)'/>
                            <img src='/images/english-language.png'/>
                        </div> -->
                    </label>
                    <fieldset v-if='options?.length'>
                        <legend>אפשרויות</legend>
                        <div class='options' v-for='(option, index) in options' :key='index'>
                            <div class='input'>
                                <div class='floatingNumber'>
                                    {{ index + 1 }}
                                </div>
                                <input v-model='option.new' @input='touch(option)' />
                                <img src='/images-new/gender.svg' @click.stop='genderize(option)'/>
                            </div>
                            <!-- <div class='input'>
                                <input class='english' v-model='o.en.text' @input='touch(o.en)'/>
                                <img src='/images/english-language.png'/>
                            </div> -->
                        </div>
                    </fieldset>
                    <button type='button' @click='send()'>שמירה</button>
                    <div class='padForScroll'></div>
                </div>
            </div>
        </div>
        <dialog-component v-if='genderText' :open='true' @close='genderText = null'>
            <template #title>טקסט מקורי: {{ genderText.text.new }}</template>
            <div id='genderize'>
                <label>
                    מילה להחלפה
                    <input v-model='genderText.word' />
                </label>
                <label>
                    בזכר
                    <input v-model='genderText.male' />
                </label>
                <label>
                    בנקבה
                    <input v-model='genderText.female' />
                </label>
                <label>
                    ברבים
                    <input v-model='genderText.other' />
                </label>
                <button type='button' @click='applyGenderize()'>בצע</button>
            </div>
        </dialog-component>
    </section>
</template>

<script lang="ts">
import { ResponseObj } from "@/ts/Request";
import { defineComponent } from "vue";

type textItem = { new: string, old: string, touched: boolean }
type RawQuestion = { id: string, text: string, options: string[] }
type Question = {
    id: string,
    text: textItem,
    options: textItem[] | null,
    touched: boolean,
    edited: boolean
};
type data = {
    selectedQuestion: Question | null,
    genderText: { text: textItem, word: string, male: string, female: string, other: string } | null,
    questions: Question[]
}

export default defineComponent({
    async beforeMount(){
        const questions: ResponseObj<RawQuestion[]> = await this.$request('/questions/edit');
        const createTextItem = (text: string): textItem => ({ new: text, old: text, touched: false })
        this.questions = questions.body.map(({ id, text, options }) => {
            return {
                id,
                text: createTextItem(text),
                options: options?.map?.(option => createTextItem(option)) ?? null,
                touched: false,
                edited: false
            }
        });
    },
    data: (): data => ({
        selectedQuestion: null,
        genderText: null,
        questions: [] as Question[],
    }),
    computed: {
        text(){
            return this.selectedQuestion?.text;
        },
        options(){
            return this.selectedQuestion?.options;
        }
    },
    methods: {
        select(question: Question){
            if(!this.selectedQuestion?.touched)
                return this.selectedQuestion = question;

            const { id, text, options } = this.selectedQuestion ?? {}

            if(question.id === id) return;
            if(!confirm(`מעבר לשאלה אחרת יבטל את השינויים שעשית. ${this.$parse('אתה בטוח', 'את בטוחה')}?`)) return;

            text.new = text.old;
            text.touched = false;
            if(options) for(const option of options){
                option.new = option.old;
                option.touched = false;
            }
            this.selectedQuestion.touched = false;
            this.selectedQuestion = question;
        },
        touch(item: textItem){
            this.selectedQuestion!.touched = item.touched = true;
        },
        async send(){
            let { text, options, id, touched } = this.selectedQuestion!;
            if(!touched) return this.selectedQuestion = null;


            const question = { id, options: [] as string[], text: text.new };

            if(options)
                for(const option of options)
                    question.options.push(option.new);

            const response = await this.$request('/questions/edit', 'put', question);
            if(!response.body.success) return;

            Object.assign(this.selectedQuestion, { edited: true, touched: false });

            text.old = text.new;
            text.touched = false;
            if(options) for(const option of options){
                option.old = option.new;
                option.touched = false;
            }
            this.selectedQuestion = null;
        },
        genderize(text: textItem){
            this.genderText = { text, word: '', male: '', female: '', other: '' }
        },
        applyGenderize(){
            let { male, female, other, word, text } = this.genderText!;

            if(!male || !female || !word) return;

            const strings = [male, female];
            if(other) strings.push(other);
            Object.assign(text, { new: text.new.replace(word, `[g:${strings.join(',')}]`), touched: true });

            this.selectedQuestion!.touched = true;
            this.genderText = null;
        },
        isGenderized(question: Question){
            const isGenderized = (text: string) => text.includes('[g:');
            if(isGenderized(question.text.old))
                return true;

            if(question.options)
                for(const option of question.options)
                    if(isGenderized(option.old))
                        return true;

            return false;
        },
    }
});
</script>

<style lang="scss" scoped>
#editor {
    display: grid;
    grid-template-columns: 2fr 3fr;

    & .categorizedList {
        @include flexbox(false, column);
        width: auto;

        & li {
            background-color: $swatchC;
            border-radius: $corner;
            @include shadow;
            padding: 0.5rem 1rem;
            cursor: pointer;

            &:hover{
                background-color: $hoverC;
            }
            &.edited {
                background-color: $swatchB;
                &:hover { background-color: $hoverB }
            }
            &.selected {
                cursor: auto;
                background-color: $swatchA;
                &:hover { background-color: $swatchA }
            }
        }
    }

    & .editing {
        padding: 2rem;
        @include flexbox(center, flex-start);

        & > div {
            width: 100%;
            position: sticky;
            top: 10vh;
            background-color: rgba(#fff, .5);
            border-radius: $corner;
            @include shadow;
            padding: 2rem;
            @include flexbox(flex-start, center, column);

            max-height: 60vh;
            overflow-y: auto;

        }
        & label {
            width: 95%;
        }
        & input{
            width: 90%;
        }
        & img {
            max-width: 1.5rem;
            margin-right: 1.5rem;
            cursor: pointer;
            transition: scale .1s;

            &:hover {
                scale: 1.2;
            }
        }
        & .input {
            @include flexbox(center-all);
            position: relative;

            & .floatingNumber {
                position: absolute;
                right: -2rem;
                font-weight: bold;
            }
            & .english {
                direction: ltr;
            }
        }
        & .options {
            @include flexbox(flex-start, column);
            margin-bottom: 1rem;
        }
        & fieldset {
            width: 80%;
            margin-top: 2rem;
        }
        & button {
            margin: 2rem 0 0;
        }
    }
}

#genderize {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-top: 1rem;
    min-width: 26rem;

    & button {
        padding: .5rem;
        background-color: $swatchC;
        border-top: $borderLight;

        &:hover {
            background-color: $hoverC;
        }
    }
}
</style>