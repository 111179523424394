
import { ResponseObj } from "@/ts/Request";
import { defineComponent } from "vue";

type textItem = { new: string, old: string, touched: boolean }
type RawQuestion = { id: string, text: string, options: string[] }
type Question = {
    id: string,
    text: textItem,
    options: textItem[] | null,
    touched: boolean,
    edited: boolean
};
type data = {
    selectedQuestion: Question | null,
    genderText: { text: textItem, word: string, male: string, female: string, other: string } | null,
    questions: Question[]
}

export default defineComponent({
    async beforeMount(){
        const questions: ResponseObj<RawQuestion[]> = await this.$request('/questions/edit');
        const createTextItem = (text: string): textItem => ({ new: text, old: text, touched: false })
        this.questions = questions.body.map(({ id, text, options }) => {
            return {
                id,
                text: createTextItem(text),
                options: options?.map?.(option => createTextItem(option)) ?? null,
                touched: false,
                edited: false
            }
        });
    },
    data: (): data => ({
        selectedQuestion: null,
        genderText: null,
        questions: [] as Question[],
    }),
    computed: {
        text(){
            return this.selectedQuestion?.text;
        },
        options(){
            return this.selectedQuestion?.options;
        }
    },
    methods: {
        select(question: Question){
            if(!this.selectedQuestion?.touched)
                return this.selectedQuestion = question;

            const { id, text, options } = this.selectedQuestion ?? {}

            if(question.id === id) return;
            if(!confirm(`מעבר לשאלה אחרת יבטל את השינויים שעשית. ${this.$parse('אתה בטוח', 'את בטוחה')}?`)) return;

            text.new = text.old;
            text.touched = false;
            if(options) for(const option of options){
                option.new = option.old;
                option.touched = false;
            }
            this.selectedQuestion.touched = false;
            this.selectedQuestion = question;
        },
        touch(item: textItem){
            this.selectedQuestion!.touched = item.touched = true;
        },
        async send(){
            let { text, options, id, touched } = this.selectedQuestion!;
            if(!touched) return this.selectedQuestion = null;


            const question = { id, options: [] as string[], text: text.new };

            if(options)
                for(const option of options)
                    question.options.push(option.new);

            const response = await this.$request('/questions/edit', 'put', question);
            if(!response.body.success) return;

            Object.assign(this.selectedQuestion, { edited: true, touched: false });

            text.old = text.new;
            text.touched = false;
            if(options) for(const option of options){
                option.old = option.new;
                option.touched = false;
            }
            this.selectedQuestion = null;
        },
        genderize(text: textItem){
            this.genderText = { text, word: '', male: '', female: '', other: '' }
        },
        applyGenderize(){
            let { male, female, other, word, text } = this.genderText!;

            if(!male || !female || !word) return;

            const strings = [male, female];
            if(other) strings.push(other);
            Object.assign(text, { new: text.new.replace(word, `[g:${strings.join(',')}]`), touched: true });

            this.selectedQuestion!.touched = true;
            this.genderText = null;
        },
        isGenderized(question: Question){
            const isGenderized = (text: string) => text.includes('[g:');
            if(isGenderized(question.text.old))
                return true;

            if(question.options)
                for(const option of question.options)
                    if(isGenderized(option.old))
                        return true;

            return false;
        },
    }
});
